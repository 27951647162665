import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import tracking from 'src/tracking'

import { Icons } from '../../components/atoms/Icon'
import { CouponCardProps } from '../../components/molecules/CouponCard'
import { HighcoCoupon } from '../../graphql/generated/api-graphql'
import { CartCouponProps } from '../../components/molecules/ReductionsList'
import { ActionButtonVariant } from '../../components/atoms/ActionButton/styled'
import Loader from '../../components/atoms/Loader'
import { colors } from '../../theme'

dayjs.extend(localizedFormat)

const isCouponAlreadyUsed = (
  coupon?: HighcoCoupon,
  selectedCoupons?: string[]
) => {
  return (
    coupon?.couponStatus === 8 ||
    (coupon?.couponStatus === 6 &&
      coupon?.couponRemainingConsumerStock != null &&
      typeof coupon?.couponRemainingConsumerStock != 'undefined' &&
      coupon?.couponRemainingConsumerStock <= 0 &&
      !selectedCoupons?.includes(coupon?.couponCode ?? ''))
  )
}

const isCouponOutOfStock = (coupon?: HighcoCoupon) =>
  (coupon?.stockOffer != null &&
    typeof coupon?.stockOffer != 'undefined' &&
    coupon?.stockOffer <= 0) ||
  coupon?.couponStatus === 9

const hasQuantity = (coupon?: HighcoCoupon) =>
  coupon?.ucNumber != null &&
  typeof coupon?.ucNumber != 'undefined' &&
  coupon?.ucNumber > 1

const isCouponLoved = (coupon?: HighcoCoupon) =>
  coupon?.flagId != null &&
  typeof coupon?.flagId != 'undefined' &&
  coupon?.flagId === 6

const isCouponCloseSoon = (coupon?: HighcoCoupon) => coupon?.closeSoon

export const couponTransformer = (
  t?: any,
  coupon?: HighcoCoupon,
  isSelected?: boolean,
  onClick?: () => void,
  selectedCoupons?: string[],
  loading?: boolean,
  disabled?: boolean
): CouponCardProps => {
  const endDate = coupon?.endDate
    ? dayjs(coupon.endDate, 'YYYY-MM-DD HH:mm:ss').format('LL')
    : null

  const title = coupon?.remainingDaysBeforeEnding
    ? t('coupons_expires_in', { days: coupon.remainingDaysBeforeEnding })
    : undefined
  const helpBoldText = endDate
    ? t('coupons_expires_on', { date: endDate })
    : undefined

  return {
    imageProps: {
      alt: coupon?.label ?? undefined,
      width: 120,
      height: 120,
      images: [
        {
          src: coupon?.productPackShotVisual ?? '',
          size: 120,
        },
      ],
    },
    brandLogoProps: {
      alt: coupon?.brandName ?? undefined,
      width: 64,
      height: 64,
      images: [
        {
          src: coupon?.brandLogo ?? '',
          size: 64,
        },
      ],
    },
    labelProps: isCouponLoved(coupon)
      ? {
          label: t('coupons_heart'),
          icon: Icons.heartFull,
        }
      : isCouponCloseSoon(coupon)
      ? {
          label: t('coupons_out_of_stock_soon'),
        }
      : coupon?.flag
      ? {
          label: coupon?.flag,
        }
      : undefined,
    shortDescription: coupon?.label ?? undefined,
    title: title,
    usedLabel: isCouponAlreadyUsed(coupon, selectedCoupons)
      ? t('coupons_used')
      : undefined,
    quantityLabel: hasQuantity(coupon)
      ? t('coupons_quantity', { quantity: coupon?.ucNumber })
      : undefined,
    outLabel: isCouponOutOfStock(coupon)
      ? t('coupons_out_of_stock')
      : undefined,
    amount: '-' + coupon?.amountFormatted ?? '',
    helpText: coupon?.couponDescription ?? undefined,
    helpBoldText: helpBoldText,
    actionButtonProps: {
      label: t('coupons_select'),
      ...(loading && {
        disabled: true,
        children: <Loader color={colors.pureWhite} />,
      }),
      ...((disabled ||
        isCouponAlreadyUsed(coupon, selectedCoupons) ||
        isCouponOutOfStock(coupon)) && {
        disabled: true,
      }),
      onClick: () => {
        {
          onClick && onClick()
          tracking.coupons.add(
            title,
            coupon?.couponId ?? 0,
            coupon?.brandName ?? undefined,
            coupon?.discountAmount ?? undefined
          )
        }
      },
    },
    ...(isSelected && {
      addedProps: {
        label: t('coupons_selected'),
        actionButtonProps: {
          label: t('coupons_unselect'),
          ...(loading && {
            disabled: true,
            children: <Loader color={colors.pureWhite} />,
          }),
          ...(disabled && {
            disabled: true,
          }),
          onClick: () => {
            {
              onClick && onClick()
              tracking.coupons.remove(
                title,
                coupon?.couponId ?? 0,
                coupon?.brandName ?? undefined,
                coupon?.discountAmount ?? undefined
              )
            }
          },
        },
      },
    }),
  }
}

export const myCouponTransformer = (
  t?: any,
  coupon?: HighcoCoupon
): CouponCardProps => {
  const endDate = coupon?.endDate
    ? dayjs(coupon.endDate, 'YYYY-MM-DD HH:mm:ss').format('LL')
    : null

  const title = coupon?.remainingDaysBeforeEnding
    ? t('coupons_expires_in', { days: coupon.remainingDaysBeforeEnding })
    : undefined
  const helpBoldText = endDate
    ? t('coupons_expires_on', { date: endDate })
    : undefined

  return {
    imageProps: {
      alt: coupon?.label ?? undefined,
      width: 120,
      height: 120,
      images: [
        {
          src: coupon?.productPackShotVisual ?? '',
          size: 120,
        },
      ],
    },
    brandLogoProps: {
      alt: coupon?.brandName ?? undefined,
      width: 64,
      height: 64,
      images: [
        {
          src: coupon?.brandLogo ?? '',
          size: 64,
        },
      ],
    },
    outLabel: isCouponOutOfStock(coupon)
      ? t('coupons_out_of_stock')
      : undefined,
    quantityLabel: hasQuantity(coupon)
      ? t('coupons_quantity', { quantity: coupon?.ucNumber })
      : undefined,
    labelProps: isCouponLoved(coupon)
      ? {
          label: t('coupons_heart'),
          icon: Icons.heartFull,
        }
      : isCouponCloseSoon(coupon)
      ? {
          label: t('coupons_out_of_stock_soon'),
        }
      : undefined,
    shortDescription: coupon?.label ?? undefined,
    title: title,
    amount: '-' + coupon?.amountFormatted ?? '',
    helpText: coupon?.couponDescription ?? undefined,
    helpBoldText: helpBoldText,
    actionButtonProps: {
      variant: ActionButtonVariant.secondary,
      label: t('coupons_print'),
      onClick: () =>
        tracking.coupons.print(
          title,
          coupon?.brandName ?? undefined,
          coupon?.discountAmount ?? undefined
        ),
    },
  }
}

export const cartCouponTransformer = (
  coupon?: HighcoCoupon,
  onClick?: () => void
): CartCouponProps => {
  const title = (coupon?.couponDescription as string) ?? undefined

  return {
    logo: {
      alt: coupon?.label ?? undefined,
      width: 120,
      height: 120,
      images: [
        {
          src: coupon?.productPackShotVisual ?? '',
          size: 120,
        },
      ],
    },
    text: title,
    reduction: '-' + coupon?.amountFormatted ?? '',
    onClick: () => {
      {
        onClick && onClick()
        tracking.coupons.remove(
          title,
          coupon?.couponId ?? 0,
          coupon?.label ?? undefined,
          coupon?.discountAmount ?? undefined
        )
      }
    },
  }
}

export const replaceRayonLabel = (str: string, t: any): string => {
  if (str === 'FROMAGE') {
    return t('coupons_filters_rayon_free')
  }
  if (str === 'FROMAGE A LA COUPE') {
    return t('coupons_filters_rayon_coupe')
  }
  return ''
}
