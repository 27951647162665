import tracking from 'src/tracking'

import type { Format } from '../../graphql/generated/api-graphql'
import { wediaImagesTransform } from '../Common/wedia'
import routes from '../../routes/definitions'
import Router from '../../routes/Router'
import { ProductPopinProps } from '../../components/molecules/ProductPopin'
import { Icons } from '../../components/atoms/Icon'
import { CouponCardsBlockProps } from '../../components/molecules/CouponCardsBlock'

const productPopinTransform = ({
  t,
  data,
  displayMoreButton,
}: {
  t: any
  data: {
    format: Format
    coupons?: CouponCardsBlockProps
  }
  displayMoreButton?: boolean
}): ProductPopinProps => {
  const transformNutritionalValue = ({
    value,
    unit,
    lessThan,
    complement,
  }: {
    value?: string
    unit?: string
    lessThan?: boolean
    complement?: {
      label?: string
      value?: string
      unit?: string
      lessThan?: boolean
    }
  }) => {
    return (
      <>
        <strong>{`${lessThan ? '<' : ''}${value}${unit}`}</strong>
        {` `}
        {complement && (
          <>
            {complement.label}
            {` `}
            <strong>{`${complement.lessThan ? '<' : ''}${complement.value}${
              complement.unit
            }`}</strong>
          </>
        )}
      </>
    )
  }

  return {
    popin: {
      title: t('product_informations'),
      texts: {
        close: t('close'),
      },
    },
    title: data?.format?.title,
    buy: {
      label: t('buy'),
      href: undefined,
      onClick: () =>
        tracking.purchase(
          data?.format?.title,
          data?.format?.mainBrand?.name ?? '',
          'consultation details'
        ),
      c2bEan: data?.format?.ean,
    },
    imageProps: {
      maxWidth: 660,
      width: 660,
      height: 264,
      withHD: true,
      alt: ' ',
      images: wediaImagesTransform(data?.format?.picture),
      placeholder: !data?.format?.picture,
    },
    thumbnailImageProps: {
      maxWidth: 265,
      width: 265,
      height: 265,
      withHD: true,
      alt: ' ',
      images: data?.format?.packshot
        ? wediaImagesTransform(data?.format?.packshot)
        : data?.format?.thumbnail
        ? wediaImagesTransform(data?.format?.thumbnail)
        : undefined,
    },
    htmlProps: {
      text: `
        ${data?.format?.body ? data?.format?.body : ''}
        ${
          data?.format?.propertyIngredients ||
          data?.format?.propertyAllergens ||
          data?.format?.propertyConservation
            ? `
            <h3>${t('whats_in_it')}</h3>
            ${
              data?.format?.propertyIngredients
                ? `
              <h4>${t('composition')}</h4>
              ${data?.format?.propertyIngredients}
            `
                : ''
            }
            ${
              data?.format?.propertyAllergens
                ? `
              <h4>${t('allergens')}</h4>
              ${data?.format?.propertyAllergens}
            `
                : ''
            }
            ${
              data?.format?.propertyConservation
                ? `
              <h4>${t('conservation')}</h4>
              ${data?.format?.propertyConservation}
            `
                : ''
            }
          `
            : ''
        }
      `,
    },
    nutrition: {
      sections: [
        ...(data?.format?.nutritionals && data?.format?.nutritionals.length > 0
          ? data?.format?.nutritionals.map((nutritional: any) => ({
              title: nutritional.servingSize
                ? t('nutritional_intake_for_', {
                    quantity: nutritional.servingSize,
                    unit: nutritional.servingSizeUnit,
                  })
                : t('nutritional_intake'),
              data: [
                ...(nutritional.fat
                  ? [
                      {
                        label: t('fat'),
                        info: transformNutritionalValue({
                          ...nutritional.fat,
                          complement: nutritional.fatsat
                            ? {
                                label: `${t('including')} ${t(
                                  'saturated_fatty_acids'
                                )}`,
                                ...nutritional.fatsat,
                              }
                            : undefined,
                        }),
                      },
                    ]
                  : []),
                ...(nutritional.choavl
                  ? [
                      {
                        label: t('carbohydrates'),
                        info: transformNutritionalValue({
                          ...nutritional.choavl,
                          complement: nutritional.sugar
                            ? {
                                label: `${t('including')} ${t('sugar')}`,
                                ...nutritional.sugar,
                              }
                            : undefined,
                        }),
                      },
                    ]
                  : []),
                ...(nutritional.pro
                  ? [
                      {
                        label: t('proteins'),
                        info: transformNutritionalValue({
                          ...nutritional.pro,
                        }),
                      },
                    ]
                  : []),
                ...(nutritional.salteq
                  ? [
                      {
                        label: t('salt'),
                        info: transformNutritionalValue({
                          ...nutritional.salteq,
                        }),
                      },
                    ]
                  : []),
                ...(nutritional.energyKj
                  ? [
                      {
                        label: t('kj_by_unit'),
                        info: transformNutritionalValue({
                          ...nutritional.energyKj,
                          complement: nutritional.energyKcal
                            ? {
                                ...nutritional.energyKcal,
                                label: ` / `,
                              }
                            : undefined,
                        }),
                      },
                    ]
                  : []),
              ],
            }))
          : []),
        {
          title: t('cheese_characteristics'),
          data: [
            ...(data?.format?.physicalChemicalExcerptDryMin
              ? [
                  {
                    label: t('dry_matter'),
                    info: transformNutritionalValue({
                      value: data?.format?.physicalChemicalExcerptDryMin,
                      unit: '%',
                    }),
                  },
                ]
              : []),
            // Commented because not on the current iteration of the site or in mockups
            // ...(data?.format?.physicalChemicalExcerptDryMax
            //   ? [
            //       {
            //         label: t('dry_matter_max'),
            //         info: transformNutritionalValue({
            //           value: data?.format?.physicalChemicalExcerptDryMax,
            //           unit: '%',
            //         }),
            //       },
            //     ]
            //   : []),
            ...(data?.format?.physicalChemicalFatOnDryWeight
              ? [
                  {
                    label: t('fat_in_dry_matter'),
                    info: transformNutritionalValue({
                      value: data?.format?.physicalChemicalFatOnDryWeight,
                      unit: '%',
                    }),
                  },
                ]
              : []),
            ...(data?.format?.physicalChemicalFatOnTotalWeight
              ? [
                  {
                    label: t('fat_in_total_weight'),
                    info: transformNutritionalValue({
                      value: data?.format?.physicalChemicalFatOnTotalWeight,
                      unit: '%',
                    }),
                  },
                ]
              : []),
          ],
        },
      ],
    },
    coupons: data?.coupons,
    seeMore:
      data?.format?.mainBrand?.slug && displayMoreButton
        ? {
            label: 'Découvrir les autres fromages de la gamme',
            href: Router.getRouteUrl(routes.brand, {
              slug: data?.format?.mainBrand.slug,
            }),
            iconPosition: 'right',
            iconProps: {
              icon: Icons.arrowRight,
            },
          }
        : undefined,
    ecoPush: data?.format?.formatTrimanCategory?.label
      ? {
          iconProps: {
            icon: Icons.triman,
          },
          htmlProps: {
            text: data?.format?.formatTrimanCategory.label,
          },
        }
      : undefined,
  }
}

export default productPopinTransform
